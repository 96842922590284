import $ from 'jquery' 
import slick from './slick'

$(document).ready(function(){
    // Desplegar menú
    $('.navbar .menu').on('click', function () {
        if($('.navbar').hasClass('active')){
            $('.navbar').removeClass('active');
        } else {
            $('.navbar').addClass('active');
        }
    });
    /* GENERAL */
    
    // Ancla para subir a la cabecera
    $.fn.scrollBottom = function() { 
        return $(document).height() - this.scrollTop() - this.height(); 
    };
    $(window).scroll(function () {
        if($(this).scrollTop() > 200){
            $('.subir').show();
        } else {
            $('.subir').hide();
        }
    });
    $('.subir').on('click', function (e) {
        e.preventDefault();
        $('html,body').animate({scrollTop: 0}, 2000);
    });
    // Carrusel
    if($('.variable-width').length != 0){
        $('.variable-width').slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            centerMode: true,
            variableWidth: true
        });
        $('.slick-prev').trigger('click');
        $('.slick-next').trigger('click');
    }
    // Input numerico
    $('.number').keypress(function (e) {
        if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
           return false;
        }
    });
    // Modales
    $('.btn-modal').on('click', function (e) {
        e.preventDefault();
        var modal = $(this).attr('data-target');
        if($('#'+modal).hasClass('active')){
            $('#'+modal).removeClass('active');
            // Eliminamos contenido HTML
        } else {
            // Añadimos contenido HTML
            $('#'+modal).addClass('active');
        }
    });
    $('.modal .cerrar').on('click', function (e) {
        e.preventDefault();
        $(this).parents().find('.modal').removeClass('active');
        // Eliminamos contenido HTML
    });
    $('.modal').on('click', function (e) {
        if($(e.target).hasClass('active')) {
            $('.modal').removeClass('active');
            // Eliminamos contenido HTML
        }
    });
    // Select
    $('.selectFiltro').on('click', function (e) {
        var selectActual = $(this).find('ul');
        if (selectActual.css('display') == 'block'){
            if(!$(e.target).hasClass('inputsearch')){
                selectActual.hide();  
            }
        } else {
            $('.selectFiltro').each(function() {
                $(this).find('ul').css('display', 'none');
            });
            selectActual.show();
        }
    });
    // Enlaces-seo
    $('.enlaces-seo .ver-mas').on('click', function (e) {
        e.preventDefault();
        var enlace = $(this);
        enlace.parents('.enlaces-seo').find('li').each(function() {
            $(this).show();
        });
        enlace.hide();
    });
    /* FICHA */
    // Desplegables de versión
    $('.ficha .desplegable h3 , footer .desplegable h3').on('click', function (e) {
        e.preventDefault();
        var elemento = $(this).parent();
        if (elemento.hasClass('abierto')){
            elemento.removeClass('abierto');
        } else {
            elemento.addClass('abierto');
        }
    });
    // Icono info
    $(window).on('click', function(e) {
        if((!$(e.target).parents().hasClass('info')) 
            && (!$(e.target).hasClass('icon-info')) 
            && ($('body').hasClass('ficha'))){
            $('.ficha .info').hide();
        }
    });
    $('.ficha .icon-info').on('click', function (e) {
        if ($(this).parents().hasClass('resumen-precio')){
            $('.ficha .resumen-precio .info').toggle();
        } else {
            $('.ficha .encabezado .info').toggle();
        }
    });
    /* ESTATICAS */
    // Anclas en Faq con scroll lento
    $('.faq a').click(function () {
        $('html, body').animate({
            scrollTop: $( $(this).attr('href') ).offset().top
        }, 1000);
    });
    // Flecha cabecera en guías
    $('.guia .icon-flecha-abajo.bajar').on('click', function (e) {
        var bloque = $('.guia .detalle');
        if ($('.guia').hasClass('home')){
            bloque = $('.guia .bloque');
        }
        $('html, body').animate({
            scrollTop: bloque.offset().top
        }, 1000);
    });
    
    /* COMPARATIVA */
    // Ancla botón comparar
    $('.comparativa .filtros .btn-blue').on('click', function () {
        if (!$('.filtros .selectFiltro').hasClass('error')){
            $('html, body').animate({
                scrollTop: $('.comparativa table').offset().top
            }, 'slow');
        }
    });
});
